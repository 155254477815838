// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~jquery-ui/dist/themes/smoothness/jquery-ui.css";
@import "loading";
@import "bootstrap-icons/font/bootstrap-icons";
@import "my_style";
@import "jquery-datetimepicker/jquery.datetimepicker.css";
@import "memo";

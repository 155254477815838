.contents-box{
    &-title{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }
}

/*コピーボタン*/
.btn-clipboard{
    border: none;
    padding-left: 10px;
    &:hover{
        color: #5596f8;
    }
    i{
        font-size: 18px;
    }
}

/* 見出し */
.members-title{
    background: #f4f4f4;
    border-left: 5px solid #000;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 5px 10px;
    height: 40px;
    line-height: 35px;
}

/* アイコン　*/
i{
    font-size: 18px;
}

/* twitter timeline */
.twitter-timeline{
    width: 340px;
}
@include media-breakpoint-up(lg) {
    .facebook-timeline {
        float: right;
    }
}

.hidden{
    display: none;
}


.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
/**
readonlyを要素に持つ場合はクリック不可
 */
select[readonly],
input[type="radio"][readonly],
input[type="checkbox"][readonly]{
    pointer-events:none;
}
